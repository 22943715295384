.marker-label {
  position: relative;
  top: 1.25rem;
  width: auto;
  padding: 0 0.25rem;
  background-color: #fff;
  border-radius: 2px;
  line-height: 1.125rem;
  opacity: 0.9;
  font-weight: bold;
}

.highlight {
  background-color: #c40000;
  font-weight: bold;
  opacity: 1;
  top: 1.45rem;
  scale: 1.4;
  width: auto;
  z-index: 9999!important;
}

.error-icon {
  display: block;
  width: 35%;
  border-radius: 5px;
  margin: 2rem auto;
  opacity: 0.8;
}

svg.light {
  fill: rgb(95, 33, 32);
}

svg.dark {
  fill: rgb(244, 199, 199);
}

.error-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #21262c;
  color: #efefef;
}

@media (max-width: 599px) {
  .MuiAlert-icon {
    margin-right: 0.35rem!important;
  }
}
